import React, { useEffect, useState } from 'react'
import Slider from '../Components/Slider'
import Solutions from '../Components/Solutions'
import Techs from '../Components/Techs'
import AboutUs from '../Components/AboutUs'
import PortfolioComp from '../Components/PortfolioComp'
import Rocket from '../Components/Rocket'
import BlogComp from '../Components/BlogComp'
import ProjectLifeCycle from '../Components/ProjectLifeCycle'
import FAQsComp from '../Components/FAQsComp'
import { Box, Stack, Typography } from "@mui/material";
import LoadingPage from '../Components/LoadingPage'
import axios from 'axios'
import { useTranslation } from 'react-i18next';

export default function MainPage() {
  const [sliders,setSliders] = useState([])
  const [service,setService] = useState([])
  const [technologies,setTechnologies] =useState([])
  const [cycle,setCycle] = useState([])
  const [open, setOpen] = useState(true);
  const [aboutUs,setAboutUs] = useState({})
  const [portfolio,setPortfolio] = useState([])
  const [blogs,setBlogs] = useState([])
  const [faqs,setFaqs] = useState([])
  const lang = localStorage.getItem('lang')
  const { t } = useTranslation();

  useEffect(()=>{
    window.scrollTo(0,0)
    axios.get(`${process.env.REACT_APP_API_URL}home`,{
      headers: {
        'Accept-Language': lang
      }
    }).then(res=>{
      setSliders(res.data.data.sliders)
      setService(res.data.data.services)
      setTechnologies(res.data.data.technologies)
      setCycle(res.data.data.projectCycle)
      setAboutUs(res.data.data.aboutUs)
      setPortfolio(res.data.data.portfolio)
      setBlogs(res.data.data.blogs)
      setFaqs(res.data.data.faqs)
      setOpen(false)
    })
},[])

  return (
    <div>
      {open && <LoadingPage open={open} />}
      {!open && <>
      <Slider sliders={sliders} />
      <Solutions service={service} />
      <Techs technologies={technologies} />
      <ProjectLifeCycle cycle={cycle} />
      <AboutUs aboutUs={aboutUs} />
      <PortfolioComp portfolio={portfolio} />
      <BlogComp blogs={blogs} />
      <div style={{ display: "flex",
            flexDirection:'column',
            justifyContent: "center",
            alignItems:'center',
            marginTop:'100px'
            }}>
      <Box
          sx={{
            mt: "0px",
            borderRadius: "30px",
            bgcolor: "#0854C1",
            p: "10px",
            height: "20px",
            width: "120px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ color: "white" }}>{t('FAQs')}</Typography>
        </Box>
        <Stack
          sx={{ alignItems: "center", justifyContent: "center", mt: "30px" }}
          gap={1}
        >
          <Typography
            sx={{ color: "black", fontWeight: "bold", fontSize: "30px" }}
          >
            {t('All Your')}
          </Typography>
          <Stack
            direction="row"
            sx={{ alignItems: "center", justifyContent: "center" }}
            gap={1}
          >
            <Typography
              sx={{ color: "black", fontWeight: "bold", fontSize: "30px" }}
            >
              {t('Questiones')}
            </Typography>
            <Typography
              sx={{ color: "#0854C1", fontWeight: "bold", fontSize: "30px" }}
            >
              {t('Answered')}
            </Typography>
          </Stack>
        </Stack>
        </div>
      <FAQsComp faqs={faqs} />
      <Rocket />
      </>}
    </div>
  )
}
