import React, { useEffect, useState } from 'react'
import { useMediaQuery, Container, Stack, Typography, Box, Button } from '@mui/material'
import Rocket from '../Components/Rocket';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoadingPage from '../Components/LoadingPage';
import axios from 'axios';

export default function ServiceDetails() {
  const [open,setOpen] = useState(true)
  const [service,setService] = useState({})
  const location = useLocation()
  const { state } = location;
  const { data } = state; 
  const lang = localStorage.getItem('lang')

  const matches = useMediaQuery('(min-width:600px)');
    useEffect(()=>{
        window.scrollTo(0,0)
        axios.get(`${process.env.REACT_APP_API_URL}service-details/${data}`,{
          headers: {
            'Accept-Language': lang
          }
        }).then(res=>{
          setOpen(false)
          setService(res.data.data.service)
        })
    },[])
    const { t } = useTranslation();

  return (
    <div style={{position:'relative'}}>

{open && <LoadingPage open={open}/>}
{matches && <Box
                        sx={{
                          position: 'absolute',
                          top: '150px',
                          right: '40%',
                          transform: 'translateX(-50%)',
                          width: '30px',
                          height: '30px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />}

{ matches && <Box
                        sx={{
                          position: 'absolute',
                          top: '500px',
                          right: '10%',
                          transform: 'translateX(-50%)',
                          width: '15px',
                          height: '15px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />
}
<Box
                        sx={{
                          position: 'absolute',
                          top: '550px',
                          left: '2%',
                          transform: 'translateX(-50%)',
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />
<Box
                        sx={{
                          position: 'absolute',
                          top: '70%',
                          left: '2%',
                          transform: 'translateX(-50%)',
                          width: '20px',
                          height: '20px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '60%',
                          right: '5%',
                          transform: 'translateX(-50%)',
                          width: '30px',
                          height: '30px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />
<Box
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '3%',
                          transform: 'translateX(-50%)',
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />
       { !open &&
       <Container maxWidth='lg'>
       <Stack direction='row' sx={{display:'flex',flexWrap:'wrap',alignItems:'center',mt:'50px'}} 
       gap={matches?30:2}>
        <Stack>
        <Container maxWidth='xs'>
        <Typography sx={{fontWeight:'bolder',fontSize:'30px',marginTop:'100px'}}>
        {service.title}
        </Typography>
        <Typography sx={{mt:'20px',color:'#0B0D39'}}>
            {service.description}
          </Typography>

         {service.benifits.length > 0 && <>
          <Typography sx={{fontWeight:'bolder',fontSize:'20px',marginTop:'50px',color:'#0854C1'}}>
          {t('Why you need it?')}
        </Typography>
        {service.benifits.map((b)=>
        <li style={{color:'#0854C1',marginTop:'10px'}}>
          {b.reason}
        </li>
        )}
        </>}

        </Container>
        </Stack>
        <img src={service.image} style={{width:matches?'400px':'300px',height:matches?'':'300px'
        ,marginTop:matches?'100px':''}}/>
        </Stack>


        <Stack direction='row' sx={{display:'flex',flexWrap:'wrap',alignItems:'center'}} gap={matches?10:2}>
        <Typography sx={{fontWeight:'bolder',fontSize:'35px',marginTop:'100px'}}>
        {t('Technologies')}
        </Typography>
        <div style={{ overflowX: 'auto',marginTop:'80px' }}> 
     {service.technologies.map((t)=> 
       <img
                src={t.image}
                height="60"
                width="100"
                style={{objectFit:'contain',marginTop:'20px'}}
                alt=""
              />)}
              </div>
        </Stack>


        
        {/* <Box sx={{display:'flex',flexWrap:'wrap',justifyContent:'center',mt:'30px'}}>
        <ImageList sx={{ width: 750, height: 600  }} variant="woven" cols={matches?3:1} gap={matches?20:8}>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img style={{objectFit:matches?'':'contain'}}
              srcSet={`${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.img}?w=161&fit=crop&auto=format`}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
    </Box> */}
        </Container>}

<Rocket />
    </div>
  )
}
