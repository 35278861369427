import {
  Box,
  Button,
  Link,
  Container,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DialogComp from "./Components/DialogComp";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const matches = useMediaQuery("(min-width:800px)");
  const [activePage, setActivePage] = useState("Home");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleNavigation = (p) => {
    let pageInEnglish;
    switch (p) {
      case t('Home'):
        pageInEnglish = 'Home';
        break;
      case t('About Us'):
        pageInEnglish = 'aboutUs';
        break;
      case t('Services'):
        pageInEnglish = 'services';
        break;
      case t('Portfolio'):
        pageInEnglish = 'portfolio';
        break;
      case t('Blog'):
        pageInEnglish = 'blog';
        break;
      case t('FAQs'):
        pageInEnglish = 'FAQs';
        break;
      case t('Contact us'):
        pageInEnglish = 'contactUs';
        break;
      default:
        pageInEnglish = p;
        break;
    }
  
    switch (pageInEnglish) {
      case 'Home':
        navigate('/');
        window.scrollTo(0, 0);
        break;
      case 'aboutUs':
        navigate('/aboutUs');
        break;
      case 'services':
        navigate('/innerService');
        break;
      case 'contactUs':
        setOpen(true);
        break;
      default:
        navigate(`/${pageInEnglish}`);
        break;
    }
  };
  
  const pages = [t("Home"), t("About Us"), t("Services"), t("Portfolio")];
  const services = [t("Blog"), t("FAQs"), t("Contact us")];
  const [contact, setContact] = useState({});
  const lang = localStorage.getItem("lang");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}home`, {
        headers: {
          "Accept-Language": lang,
        },
      })
      .then((res) => {
        setContact(res.data.data.general);
      });
  }, []);
  const handleMailClick = () => {
    window.open(`mailto:${contact.email}`);
  };
  return (
    <div style={{ marginTop: "100px" , backgroundColor:'#EDF4FF',padding:'20px'}}>
      <Container maxWidth="lg">
        <Stack
          direction="row"
          gap={matches?15:5}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "start",
            alignItems: "start",
          }}
        >
          <Stack>
            <Box
              sx={{
                mt: "0px",
                borderRadius: "30px",
                bgcolor: "#0854C1",
                p: "10px",
                height: "20px",
                width: "100px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ color: "white" }}>{t("contact us")}</Typography>
            </Box>
            <Typography
              sx={{
                fontSize: matches ? "25px" : "",
                fontWeight: matches ? "" : "bold",
                mt: "20px",
              }}
            >
              {contact.email}
              <button
                className="btn"
                onClick={handleMailClick}
                style={{ margin: "20px" }}
              >
                <ArrowUpwardIcon style={{ transform: "rotate(45deg)" }} />
              </button>
            </Typography>

            <Stack
              direction="row"
              gap={2}
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Typography sx={{ mt: "20px", color: "#0B0D39" }}>
                {t("Social Media")}
              </Typography>
              {contact.instagram && (
                <Link href={contact.instagram} target="_blank">
                  <button className="btn2">
                    <InstagramIcon />
                  </button>
                </Link>
              )}

              {contact.linkedin && (
                <Link href={contact.linkedin} target="_blank">
                  <button className="btn2">
                    <LinkedInIcon />
                  </button>
                </Link>
              )}

              {contact.facebook && (
                <Link href={contact.facebook} target="_blank">
                  <button className="btn2">
                    <FacebookIcon />
                  </button>
                </Link>
              )}

              {contact.phone && (
                <Link href={`https://wa.me/${contact.phone}`} target="_blank">
                  <button className="btn2">
                    <WhatsAppIcon />
                  </button>
                </Link>
              )}
            </Stack>
          </Stack>

          <Stack sx={{ mt: "40px" }}>
            {pages.map((page) => (
              <Button
                key={page}
                sx={{
                  color: "#0B0D39",
                  display: "flex",
                  fontSize: "12px",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: activePage === page ? "bold" : "normal",
                  textTransform: "none",
                }}
                onClick={() => {
                  handleNavigation(page);
                  setActivePage(page);
                }}
              >
                {activePage === page && (
                  <Box
                    sx={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      bgcolor: "#356DFF",
                      mr: "5px",
                    }}
                  />
                )}
                {page}
              </Button>
            ))}
          </Stack>

          <Stack sx={{ mt: "40px" }}>
            {services.map((page) => (
              <Button
                key={page}
                sx={{
                  color: "#0B0D39",
                  display: "flex",
                  fontSize: "12px",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: activePage === page ? "bold" : "normal",
                  textTransform: "none",
                }}
                onClick={() => {
                  handleNavigation(page);
                  setActivePage(page);
                }}
              >
                {activePage === page && (
                  <Box
                    sx={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      bgcolor: "#356DFF",
                      mr: "5px",
                    }}
                  />
                )}
                {page}
              </Button>
            ))}
          </Stack>

          <Button
            key="Privacy & Terms"
            sx={{
              color: "#0B0D39",
              mt: "40px",
              display: "flex",
              fontSize: "12px",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: activePage === "Privacy & Terms" ? "bold" : "normal",
              textTransform: "none",
            }}
            onClick={() => {
              handleNavigation("PrivacyPolicy");
              setActivePage("Privacy & Terms");
            }}
          >
            {activePage === "Privacy & Terms" && (
              <Box
                sx={{
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  bgcolor: "#356DFF",
                  mr: "5px",
                }}
              />
            )}
            {t("Privacy & Terms")}
          </Button>
        </Stack>

        <Divider sx={{ width: "100%", mt: "30px" }} />
        <Typography
          sx={{
            color: "#0B0D39",
            mt: "20px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {t("Copyright")} {new Date().getFullYear()}{" "}
          {t("SmartEdge Solutions. All rights reserved.")}
        </Typography>
      </Container>

      <DialogComp open={open} setOpen={setOpen} />
    </div>
  );
}
