import { Box, Button, Container, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import LoadingPage from '../Components/LoadingPage';


export default function BlogDetail() {
  const location = useLocation()
  const { state } = location;
  const { data } = state; 
  const navigate = useNavigate()
  const { t } = useTranslation();
  const [open,setOpen] = useState(true)
  const [service,setService] = useState({})
  const lang = localStorage.getItem('lang')
  const [blogs,setBloga] = useState([])

    useEffect(()=>{
        window.scrollTo(0,0)
        axios.get(`${process.env.REACT_APP_API_URL}blog-details/${data}`,{
          headers: {
            'Accept-Language': lang
          }
        }).then(res=>{
          setOpen(false)
          setService(res.data.data.blog)
        })
        axios.get(`${process.env.REACT_APP_API_URL}blog`,{
          headers: {
            'Accept-Language': lang
          }
        }).then(res=>{
          setBloga(res.data.data.blogs)
        })
      },[])
  return (
    <div style={{marginTop:'180px',display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',position:'relative'}}>

<Box
                        sx={{
                          position: 'absolute',
                          top: '0px',
                          left: '2%',
                          transform: 'translateX(-50%)',
                          width: '15px',
                          height: '15px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />


<Box
                        sx={{
                          position: 'absolute',
                          top: '450px',
                          left: '2%',
                          transform: 'translateX(-50%)',
                          width: '20px',
                          height: '20px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />


<Box
                        sx={{
                          position: 'absolute',
                          top: '70%',
                          right: '2%',
                          transform: 'translateX(-50%)',
                          width: '20px',
                          height: '20px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />

<Box
                        sx={{
                          position: 'absolute',
                          top: '90%',
                          right: '2%',
                          transform: 'translateX(-50%)',
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />


<Box
                        sx={{
                          position: 'absolute',
                          top: '110%',
                          right: '7%',
                          transform: 'translateX(-50%)',
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />

{open && <LoadingPage open={open}/>}

{!open &&
      <Container maxWidth='lg'>
        <Typography sx={{fontWeight:'bold',fontSize:'35px'}}>
        {service.title}
        </Typography>

        <Stack  direction='row' sx={{mt:'30px'}}>
        <Box sx={{display:'flex',alignItems:'start'
      ,flexWrap:'wrap',justifyContent:'start',width:'50%'}}>
        <Typography sx={{color:'#0854C1',fontWeight:'bold'}}>
        {service.date}
        </Typography>
        </Box>
        <Box sx={{display:'flex',alignItems:'end'
      ,flexWrap:'wrap',justifyContent:'end',width:'50%'}}>
  <Typography sx={{color:'#0854C1',fontWeight:'bold'}}>
  {service.duration} {t('min read')}
        </Typography>
        </Box>
        </Stack>

        <img src={service.image} style={{marginTop:'30px',width:'100%',height:'400px'}}/>
        
        <Typography sx={{color:'#888888',mt:'30px'}}>
       {service.description}
        </Typography>


        <Typography sx={{mt:'60px',fontWeight:'bold',mb:'20px',fontSize:'20px'}}>
            {t('More Articles')}
        </Typography>


        <div style={{ overflowX: 'auto' }}>
        <Stack direction='row' sx={{justifyContent:'start'
      ,alignItems:'start' ,position:'relative'}}>
        {blogs.map((b)=>
        <Container sx={{width:'300px',display:'flex',flexWrap:'wrap',justifyContent:'start'
      ,alignItems:'start',margin:"0px"}}>
        <img style={{width:'200px',height:'200px',objectFit:'cover'}} src={b.image}/>
        <Typography sx={{color:'black',fontWeight:'bold'}}>
        {b.title}
        </Typography>

        <Button sx={{backgroundColor:'transparent',color:'#0854C1',marginTop:'10px','&:hover': { 
            backgroundColor:'transparent',color:'#0854C1'
         }}} onClick={()=>{navigate('/blogdetail',{state:{data:b.id}});
         window.location.reload(true)
        }}>
        {t('Read more')}   
          <ArrowUpwardIcon style={{ transform: 'rotate(45deg)'}}/>
        </Button>
        </Container>
        )}
        
      </Stack>
      </div>
      </Container>
      }
    </div>
  )
}
