import React, { useEffect, useState } from 'react'
import { Box, Container, Typography } from '@mui/material'
import Rocket from '../Components/Rocket'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import LoadingPage from '../Components/LoadingPage'
import { useTranslation } from 'react-i18next';

export default function Portfolio() {
  const [port,setPort] = useState([])
  const [open, setOpen] = useState(true);
  const lang = localStorage.getItem('lang')
  const { t } = useTranslation();

    const navigate = useNavigate()
    useEffect(()=>{
      window.scrollTo(0,0)
      axios.get(`${process.env.REACT_APP_API_URL}home`,{
        headers: {
          'Accept-Language': lang
        }
      }).then(res=>{
        setPort(res.data.data.portfolio)
        setOpen(false)
      })
  },[])
  return (
    <div style={{marginTop:'150px'}}>

<Box
                        sx={{
                          position: 'absolute',
                          top: '150px',
                          right: '7%',
                          transform: 'translateX(-50%)',
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '200px',
                          left: '10%',
                          transform: 'translateX(-50%)',
                          width: '15px',
                          height: '15px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '500px',
                          right: '1%',
                          transform: 'translateX(-50%)',
                          width: '15px',
                          height: '15px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />
                         <Box
                        sx={{
                          position: 'absolute',
                          top: '750px',
                          left: '5%',
                          transform: 'translateX(-50%)',
                          width: '15px',
                          height: '15px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />
                      {open && <LoadingPage open={open} />}
      <Typography 
      sx={{display:'flex',flexWrap:'wrap',alignItems:'center',justifyContent:'center',
      fontSize:'20px',fontWeight:'bold',color:'#0854C1'}}>{t('Our Projects')}</Typography>

       {!open && <>
       <Container maxWidth='lg' sx={{display:'flex',flexWrap:'wrap',justifyContent:'center'
        ,alignItems:'center'
      ,gap:'50px',mt:'40px'}}>


      {port.map((p)=>
      <div class="p_card">
  <img
    class="p_card__background"
    src={p.logo}
    width="1920"
    height="219"
  />
  <div class="p_card__content | flow">
    <div class="p_card__content--container | flow">
      <h2 class="p_card__title">{p.title}</h2>
      <p class="p_card__description">
      {p.description.substring(0, 50)}
      </p>
    </div>
    <div style={{padding:'10px'}}>
    <button class="p_card__button" onClick={()=>{navigate('/projectdetails',{ state: { data: p.id } })}}>{t('Read more')}</button>
    </div>
  </div>
</div>
)}
</Container>

<Rocket />
</>}

    </div>
  )
}
