import { HelmetProvider } from 'react-helmet-async';
import { HashRouter, Route, Routes } from 'react-router-dom';
import MainPage from './Pages/MainPage';
import Navbar from './Navbar';
import Footer from './Footer';
import AboutUs from './Pages/AboutUs';
import Blog from './Pages/Blog';
import BlogDetail from './Pages/BlogDetail';
import FAQs from './Pages/FAQs';
import ProjectDetails from './Pages/ProjectDetails';
import ServiceDetails from './Pages/ServiceDetails';
import InnerService from './Pages/InnerService';
import Portfolio from './Pages/Portfolio';
import PrivacyPolice from './Pages/PrivacyPolice';
import { t } from 'i18next';
import { useEffect } from 'react';

function App() {
  const lang = localStorage.getItem('lang')

  useEffect(()=>{
    if(!lang){
      localStorage.setItem('lang','en')
    }
  },[])
  
  return (
    <div>
          <HelmetProvider>
          <HashRouter>
            <Navbar />
          <Routes>
            <Route path="/" element= {<MainPage />}/>
            <Route path="/aboutUs" element= {<AboutUs />}/>
            <Route path="/blog" element= {<Blog />}/>
            <Route path="/blogdetail" element= {<BlogDetail />}/>
            <Route path="/FAQs" element= {<FAQs />}/>
            <Route path="/projectdetails" element= {<ProjectDetails />}/>
            <Route path="/servicedetails" element= {<ServiceDetails />}/>
            <Route path="/innerservice" element= {<InnerService />}/>
            <Route path="/portfolio" element= {<Portfolio />}/>
            <Route path="/PrivacyPolicy" element= {<PrivacyPolice />}/>
            </Routes>
            <Footer />
          </HashRouter>
          </HelmetProvider>
    </div>
  );
}

export default App;
