import React, { useEffect, useState } from 'react'
import { useMediaQuery, Container, Stack, Typography, Box, Button } from '@mui/material'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Rocket from '../Components/Rocket';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import LoadingPage from '../Components/LoadingPage';

export default function ProjectDetails() {
  const location = useLocation()
  const { state } = location;
  const { data } = state; 
  const [open,setOpen] = useState(true)
  const lang = localStorage.getItem('lang')
  const [port,setPort] = useState({})
    const matches = useMediaQuery('(min-width:600px)');
    const [activePage, setActivePage] = useState('');
    useEffect(()=>{
        window.scrollTo(0,0)
        axios.get(`${process.env.REACT_APP_API_URL}portfolio-details/${data}`,{
          headers: {
            'Accept-Language': lang
          }
        }).then(res=>{
          console.log(res.data.data)
          setOpen(false)
          setPort(res.data.data.portfolio)
        })
    },[])
    const { t } = useTranslation();

  return (
    <div style={{position:'relative'}}>

{matches && <Box
                        sx={{
                          position: 'absolute',
                          top: '150px',
                          right: '40%',
                          transform: 'translateX(-50%)',
                          width: '30px',
                          height: '30px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />}

<Box
                        sx={{
                          position: 'absolute',
                          top: '500px',
                          right: '10%',
                          transform: 'translateX(-50%)',
                          width: '15px',
                          height: '15px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />

<Box
                        sx={{
                          position: 'absolute',
                          top: '500px',
                          left: '5%',
                          transform: 'translateX(-50%)',
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />
<Box
                        sx={{
                          position: 'absolute',
                          top: '70%',
                          left: '2%',
                          transform: 'translateX(-50%)',
                          width: '20px',
                          height: '20px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '60%',
                          right: '5%',
                          transform: 'translateX(-50%)',
                          width: '30px',
                          height: '30px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />
<Box
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '3%',
                          transform: 'translateX(-50%)',
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />
                      {open && <LoadingPage open={open}/>}
      { !open &&  <Container maxWidth='lg'>
       <Stack direction='row' sx={{display:'flex',flexWrap:'wrap',alignItems:'center'}} gap={matches?30:2}>
        <Stack>
        <Container maxWidth='xs'>
        <Typography sx={{fontWeight:'bolder',fontSize:'30px',marginTop:'100px'}}>
        {port.title}
        </Typography>
        <Typography sx={{mt:'20px',color:'#0B0D39'}}>
        {port.description}
        </Typography>
        </Container>
        </Stack>
        <img src={data.logo} style={{width:matches?'400px':'300px',height:matches?'':'300px'
        ,marginTop:matches?'100px':''}}/>
        </Stack>


        <Stack direction='row' sx={{display:'flex',flexWrap:'wrap',alignItems:'center'}} gap={matches?10:2}>
        <Typography sx={{fontWeight:'bolder',fontSize:'35px',marginTop:'100px'}}>
        {t('Technologies')}
        </Typography>
        <div style={{ marginTop:'80px' }}> 
      {port.technologies && port.technologies.map((t)=> <img
    src={t.image}
    height="60"
    width="100"
    style={{objectFit:'contain',marginTop:'20px'}}
    alt=""
  />)}
              </div>
        </Stack>


        <Typography sx={{fontWeight:'bolder',fontSize:'35px',marginTop:'100px'}}>
        {t('Services')}
        </Typography>
        <Stack direction='row' sx={{display:'flex',flexWrap:'wrap',alignItems:'center',mt:'0px'}} gap={matches?10:2}>
            {port.services && port.services.map((s)=>
        <Button
                    sx={{
                        color: activePage === s ?'white':'#0854C1',
                        bgcolor: activePage === s ? '#0854C1' : 'transparent',
                        '&:hover': { color: 'white', bgcolor: '#0854C1' },
                        borderRadius: '30px 30px 30px 0px',textTransform:'none',width:'120px',mt:'50px',
                    border:activePage===s?'':'1px solid #0854C1'}}
                  onClick={()=>{setActivePage(s)}}>
                    {s.title}
                  </Button>
                  )}
        </Stack>


        {activePage !== null ? 
        <Stack direction='row' 
        sx={{display:'flex',flexWrap:'wrap',alignItems:'center',mb:activePage  !== null&&'150px',
        mt:activePage !== null && '20px'}} gap={matches?30:2}>
        <Stack>
        <Container maxWidth='xs'>
        <Typography sx={{fontWeight:'bolder',fontSize:'30px',marginTop:'100px'}}>
        {activePage.title}
        </Typography>
        <Typography sx={{mt:'20px',color:'#0B0D39'}}>
        {activePage.description}
        </Typography>
        </Container>
        </Stack>
        <img src={activePage.image} style={{width:matches?'200px':'300px',height:matches?'':'100px'
        }}/>
        </Stack>:null}




        <Typography sx={{fontWeight:'bolder',fontSize:'35px',marginTop:'100px'}}>
        {t('Media')}
        </Typography>
        <Box sx={{display:'flex',flexWrap:'wrap',justifyContent:'center',mt:'30px'}}>
        <ImageList sx={{ width: 750, height: 400  }} variant="woven" cols={matches?3:1} gap={matches?20:8}>
      {port.media && port.media.map((item) => (
        <ImageListItem key={item.image}>
          <img style={{objectFit:matches?'':'contain'}}
              srcSet={`${item.image}?w=161&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.image}?w=161&fit=crop&auto=format`}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
    </Box>
        </Container>}

<Rocket />
    </div>
  )
}
