import { Box, Container, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';

export default function ProjectLifeCycle({cycle}) {
  const { t } = useTranslation();
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <div
      style={{
        marginTop: "100px",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "20px",
          left: "30px",
          transform: "translateX(-50%)",
          width: "15px",
          height: "15px",
          borderRadius: "50%",
          bgcolor: "#0854C1",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "200px",
          right: "20%",
          transform: "translateX(-50%)",
          width: "15px",
          height: "15px",
          borderRadius: "50%",
          bgcolor: "#0854C1",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "500px",
          left: "95%",
          transform: "translateX(-50%)",
          width: "30px",
          height: "30px",
          borderRadius: "50%",
          bgcolor: "#0854C1",
        }}
      />

   {matches &&   <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "15%",
          transform: "translateX(-50%)",
          width: "20px",
          height: "20px",
          borderRadius: "50%",
          bgcolor: "#0854C1",
        }}
      />}

      <Box
        sx={{
          position: "absolute",
          top: "850px",
          right: "2%",
          transform: "translateX(-50%)",
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          bgcolor: "#0854C1",
        }}
      />
<Stack sx={{ display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",}}>
<Box
        sx={{
          mb: "20px",
          borderRadius: "30px",
          bgcolor: "#0854C1",
          p: "10px",
          height: "20px",
          width: "150px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ color: "white" }}>{t('Projects Life Cycle')}</Typography>
      </Box>
      <div class="outer">
        {cycle.map((c,i)=>
        <motion.div
          class="card"
          initial={{ opacity: 0, x: i%2 === 0 ? 100 :-100 }}
          whileInView={{ opacity: 1 }}
          animate={{ x: 0 }}
          transition={{ delay: 0.7, type: "spring", stiffness: 100 }}
          viewport={{ once: true }}
        >
          <div class="info">
            <img src={c.image} style={{borderRadius:'100%',width:'60px',height:'60px',objectFit:'contain'}}/>
            <h3 class="title">{c.title}</h3>
            <Container maxWidth='sm'>
            <li> {c.description} </li>
            </Container>
            <Typography sx={{mt:'10px',color:'#0854C1'}}>{c.result}</Typography>
          </div>
        </motion.div>
        )}
      </div>
      </Stack>
    </div>
  );
}
