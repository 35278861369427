import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function BlogComp({blogs}) {
  const navigate = useNavigate()
  const { t } = useTranslation();

  return (
    <div style={{marginTop:'100px',display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',position:'relative'}}>

<Box
                        sx={{
                          position: 'absolute',
                          top: '500px',
                          right: '3%',
                          transform: 'translateX(-50%)',
                          width: '15px',
                          height: '15px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                        }}
                      />

<Box
                        sx={{
                          position: 'absolute',
                          top: '20px',
                          left: '10%',
                          transform: 'translateX(-50%)',
                          width: '15px',
                          height: '15px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                        }}
                      />


        <Stack sx={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center'}}>
      <Box sx={{mt:'0px',borderRadius:'30px',bgcolor:'#0854C1',p:'10px',height:'20px',width:'120px',display:'flex'
      ,flexWrap:'wrap',justifyContent:'center'}}>
            <Typography sx={{color:'white'}}>
            {t('Blog')}
            </Typography>
            </Box>
            <Stack direction='row' sx={{alignItems:'center',justifyContent:'center',mt:'30px'}} gap={1}>
            <Typography sx={{color:'#0854C1',fontWeight:'bold',fontSize:'30px'}}>
            {t('Knowledge')}  
            </Typography>
            <Typography sx={{color:'black',fontWeight:'bold',fontSize:'30px'}}>
            {t('Hub')} 
            </Typography>
            </Stack>

        <Stack  direction='row' sx={{display:'flex',flexWrap:'wrap',alignItems:'center',justifyContent:'center',mt:'30px'}} gap={4}>
       { blogs.slice(0, 3).map((b)=><Card sx={{ borderRadius:'15px 15px 15px 5px',width:'300px',boxShadow: 'rgba(149, 157, 165, 0.2) 8px 8px 24px 8px'}}>
            <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',padding:'20px'}}>
    <img src={b.image} style={{width:'270px',height:'200px',objectFit:'cover',borderRadius:'20px'}}/>
    </div>
    <CardContent>
      <Typography gutterBottom variant="h5" component="div">
     {b.title}
      </Typography>
      <Typography gutterBottom>
      {b.description.substring(0, 50)}
      </Typography>
       <Stack direction='row' sx={{alignItems:'start',justifyContent:'start',mt:'30px',position:'relative'}} gap={7}>
      <Typography sx={{color:'#0B0D39'}}>
      {b.duration} · {b.date}
      </Typography>
      {/* <button className='b'>
        <ArrowForwardIcon />
      </button> */}
       <button className={`b ${localStorage.getItem('lang') === 'en' ? 'en' : 'ar'}`} 
      onClick={()=>{navigate('/blogdetail',{state:{data:b.id }})}}>
        {localStorage.getItem('lang') === 'ar' ? 
        <ArrowBackIcon />
        :
        <ArrowForwardIcon/>}
      </button>
      </Stack>
    </CardContent>
</Card>)}
</Stack>

<Button sx={{  color: '#0B0D39',mt:'30px',
                      bgcolor: 'transparent',
                      '&:hover': { color: '#0B0D39', bgcolor: 'transparent' },
                      padding: '10px 20px 10px 20px',border:'1px solid black',
                      borderRadius: '30px 30px 30px 0px',textTransform:'none',width:'120px'}}
                      onClick={()=>{navigate('/blog');
                      localStorage.setItem('p','blog');       
                      window.location.reload(true);}}>
{t('View more')}
</Button>
</Stack>
    </div>
  )
}
