import { Box, Container, Stack, Typography,useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import Video from "../Components/Video";
import Rocket from "../Components/Rocket";
import AboutCards from "../Components/AboutCards";
import axios from "axios";
import LoadingPage from "../Components/LoadingPage";
import { useTranslation } from 'react-i18next';

export default function AboutUs() {
  const [open, setOpen] = useState(true);
  const [aboutUs,setAboutUs] = useState({})
  const lang = localStorage.getItem('lang')
  const { t } = useTranslation();
  const matches = useMediaQuery('(min-width:600px)');

  useEffect(()=>{
    window.scrollTo(0,0)
    axios.get(`${process.env.REACT_APP_API_URL}about`,{
      headers: {
        'Accept-Language': lang
      }
    }).then(res=>{
      setOpen(false)
      console.log(res.data.data.about)
      setAboutUs(res.data.data.about)
    })
},[])
  return (
    <div style={{ marginTop: "150px", position: "relative" }}>
      {open && <LoadingPage open={open} />}
   {  !open && <> <Container maxWidth="lg">
        <Stack
          direction="row"
          gap={10}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack>
              <Container maxWidth='sm'>
              <Typography
                sx={{ color: "black", fontWeight: "bold", fontSize: "30px" }}
              >
                {aboutUs.title}
              </Typography>
              </Container>
        

            <Container maxWidth="sm" sx={{ mt: "20px" }}>
              <Typography sx={{ color: "#0B0D39" }}
              dangerouslySetInnerHTML={{ __html: aboutUs.content }}>
              </Typography>
            </Container>
          </Stack>

          <img src={aboutUs.image} style={{ width: "350px", height: "350px", objectFit:'cover' }} />
        </Stack>
      </Container>

   { matches &&  <Box
        sx={{
          position: "absolute",
          top: "-15px",
          left: "65%",
          transform: "translateX(-50%)",
          width: "30px",
          height: "30px",
          borderRadius: "50%",
          bgcolor: "#0854C1",
        }}
      />}

      <Box
        sx={{
          position: "absolute",
          top: "20px",
          left: "5%",
          transform: "translateX(-50%)",
          width: "15px",
          height: "15px",
          borderRadius: "50%",
          bgcolor: "#0854C1",
        }}
      />

      <Box
        sx={{
          position: "absolute",
          top: "300px",
          left: "90%",
          transform: "translateX(-50%)",
          width: "15px",
          height: "15px",
          borderRadius: "50%",
          bgcolor: "#0854C1",
        }}
      />

      <Box
        sx={{
          position: "absolute",
          top: "450px",
          left: "2%",
          transform: "translateX(-50%)",
          width: "20px",
          height: "20px",
          borderRadius: "50%",
          bgcolor: "#0854C1",
        }}
      />

      <Video vid={aboutUs.promo_video} />
      <Stack
        direction="row"
        gap={3}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          mt: "100px",
        }}
      >
        <AboutCards
          title={t("Our Mission")}
          content={aboutUs.mission}
        />
        <AboutCards
          title={t("Our Vision")}
          content={aboutUs.vision}
        />
      </Stack>
      <Rocket />
      </>}
    </div>
  );
}
