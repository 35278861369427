import { Box, Divider, Stack, Typography } from "@mui/material";
import React,{useState} from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Container,
  } from '@mui/material';
  import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
  import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function FAQsComp({faqs}) {
    const [expandedIndex, setExpandedIndex] = useState(null);

    const handleAccordionChange = (index) => {
      setExpandedIndex(index === expandedIndex ? null : index);
    };
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack gap={1} sx={{mt:'30px'}}>
             {faqs.map((f, index)=>
              <Accordion
                 key={index}
                 elevation={0}
                 expanded={expandedIndex === index}
                 onChange={() => handleAccordionChange(index)}
                classes={{ root: 'accordion-root' }}
              >
                <AccordionSummary
                  expandIcon={expandedIndex === index ? 
                  <KeyboardArrowUpIcon sx={{ color: '#0854C1' }} /> : 
                  <KeyboardArrowDownIcon sx={{ color: '#0854C1' }} />}
                  sx={{
                    width: '100%',
                    color:'#0854C1',
                  }}
                >
                  <Typography sx={{ color: '#0854C1', fontSize: '15px'}}>
                  {f.question}
                  </Typography>
                </AccordionSummary>
                <Divider sx={{width:'100%'}}/>
                <AccordionDetails>
                  <Typography sx={{ color: '#666666', fontSize: '15px' }}>
                  <Container maxWidth='sm' sx={{textAlign:'center'}}>
                  {f.answer}
                    </Container>
                  </Typography>
                  {/* {q.attachment_mime_type === 'video/mp4' &&
                   <video src={`${process.env.REACT_APP_API_URL_IMAGE}${q.attachment}`} controls style={{ marginTop: '20px', width: '100%', height: '200px' }} />
                   }
                    {q.attachment_mime_type === 'image/png' &&
                   <img src={`${process.env.REACT_APP_API_URL_IMAGE}${q.attachment}`} 
                    style={{ marginTop: '20px', width: '100%', height: '200px' ,objectFit:'contain'}} />
                   } */}
                {
  f.image && f.image.endsWith('.mp4') ? (
    <video
      src={f.image}
      style={{ marginTop: '20px', width: '320px', height: '200px', objectFit: 'cover' }}
      controls
    />
  ) : (
    <img
      src={f.image}
      style={{ marginTop: '20px', width: '320px', height: '200px', objectFit: 'cover' }}
    />
  )
}
                </AccordionDetails>
              </Accordion>)}
          </Stack>
      </Stack>
    </div>
  );
}
