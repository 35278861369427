import { Box, Container, Stack, Typography, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import TechDialog from './TechDialog';

export default function Techs({technologies}) {
  const { t } = useTranslation();
  const [open,setOpen] = useState(false)
  const [data,setData] = useState({})

  return (
    <div style={{marginTop:'80px'}}>

      <Stack sx={{display:'flex',alignItems:'center'
      ,flexWrap:'wrap',justifyContent:'center'}}>
     <Box sx={{mt:'30px',borderRadius:'30px',bgcolor:'#0854C1',p:'10px',height:'20px',width:'120px',display:'flex'
      ,flexWrap:'wrap',justifyContent:'center'}}>
            <Typography sx={{color:'white'}}>
            {t('Technologies')}
            </Typography>
        </Box>
      <Container maxWidth='md' sx={{display:'flex',flexWrap:'wrap'
      ,justifyContent:'center',alignItems:'center',gap:'10px',textAlign:'center',mt:'20px'}}>
                 {technologies.map((tec)=>
                                     <Tooltip title={tec.title}>
                                     <img
                src={tec.image}
                height="60"
                width="100"
                style={{objectFit:'contain',marginTop:'20px',cursor:'pointer'}}
                alt=""
                onClick={()=>{setOpen(true);setData(tec)}}
              />
                            </Tooltip>
)}
      </Container>
      </Stack>
      <TechDialog open={open} setOpen={setOpen} data={data} />
    </div>
  )
}
