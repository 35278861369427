import { Box, Button, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

export default function Solutions({service}) {
  const navigate = useNavigate()
  const { t } = useTranslation();

  return (
    <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',marginTop:'100px'
    ,position:'relative'}}>
               <Box
                        sx={{
                          position: 'absolute',
                          top: '-10px',
                          left: '80%',
                          transform: 'translateX(-50%)',
                          width: '30px',
                          height: '30px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                        }}
                      />
      <Box sx={{bgcolor:'#EDF4FF',borderRadius:'20px',width:'90%',minHeight:'400px',display:'flex'
      ,flexWrap:'wrap',justifyContent:'center'}}>
        <Stack sx={{display:'flex',alignItems:'center'
      ,flexWrap:'wrap',justifyContent:'center'}}>
        <Box sx={{mt:'30px',borderRadius:'30px',bgcolor:'#0854C1',p:'10px',height:'20px',width:'120px',display:'flex'
      ,flexWrap:'wrap',justifyContent:'center'}}>
            <Typography sx={{color:'white'}}>
            {t('Services')}
            </Typography>
        </Box>
        <Stack direction='row' sx={{display:'flex',alignItems:'center'
      ,flexWrap:'wrap',justifyContent:'center',mt:'30px'}} gap={1}>
        <Typography sx={{color:'black',fontWeight:'bold',fontSize:'30px'}}>
        {t('Your Dream')}
            </Typography>
            <Typography sx={{color:'#0854C1',fontWeight:'bold',fontSize:'30px'}}>
        {t('Team')}
            </Typography>
            </Stack>


            <Stack
            gap={5}
          direction="row"
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',mt:"30px"
          }}
        >


          {service.slice(0, 4).map((s)=> <Stack
  sx={{
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '30px',
  }}
  gap={2}
>
  <img src={s.image} style={{ objectFit: 'cover', width: '150px',height:'75px' }} />
  <Typography sx={{ fontWeight: 'bold', fontSize: '15px' }}>
    {s.title}
  </Typography>
    <Typography
      sx={{
        fontSize: '10px',
      }}
    >
          <Container sx={{ textAlign: 'center',width:'200px' }}>
      {s.description && s.description.substring(0, 50)}...<Button sx={{textTransform:'none',color:'#0854C1'}}
      onClick={()=>{navigate('/servicedetails',{state :{data : s.id}})}}>{t('Read More')}</Button>
      </Container>
    </Typography>
</Stack>)}


          </Stack>
          <Button sx={{  color: '#0854C1',mt:'30px',
                      bgcolor: 'transparent',
                      '&:hover': { color: '#0854C1', bgcolor: 'transparent' },
                      padding: '10px 20px 10px 20px',border:'1px solid #0854C1',
                      borderRadius: '30px 30px 30px 0px',textTransform:'none',width:'120px',mb:'30px'}}
                      onClick={()=>{navigate('/innerService');
                      localStorage.setItem('p','services');       
                      window.location.reload(true);}}>
{t('View more')}
</Button>
            </Stack>
      </Box>
    </div>
  )
}
