import { Box, Container, Stack, Typography,useMediaQuery } from '@mui/material'
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay,Navigation } from 'swiper/modules';
import 'swiper/css/navigation';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import '../App.css'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import '../App.css'
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

export default function PortfolioComp({portfolio}) {
    const matches = useMediaQuery('(min-width:1000px)');
    const navigate = useNavigate()
    const { t } = useTranslation();


  return (
    <div style={{marginTop:'100px',position:'relative',display:'flex',alignItems:'center'
    ,flexWrap:'wrap',justifyContent:'center'}}>
        <Box
                        sx={{
                          position: 'absolute',
                          top: '50px',
                          left: '5%',
                          transform: 'translateX(-50%)',
                          width: '30px',
                          height: '30px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',zIndex:1
                        }}
                      />

<Box
                        sx={{
                          position: 'absolute',
                          top: '500px',
                          left: '3%',
                          transform: 'translateX(-50%)',
                          width: '15px',
                          height: '15px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                        }}
                      />

<Box
                        sx={{
                          position: 'absolute',
                          top: '640px',
                          right: '10%',
                          transform: 'translateX(-50%)',
                          width: '15px',
                          height: '15px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />

        <Box sx={{bgcolor:'#EDF4FF',borderRadius:'20px',width:'90%',minHeight:'650px',
      position:'relative'}}>
    <Stack sx={{display:'flex',alignItems:'center'
      ,flexWrap:'wrap',justifyContent:'center'}}>
        <Box sx={{mt:'30px',borderRadius:'30px',bgcolor:'#0854C1',p:'10px',height:'20px',width:'120px',display:'flex'
      ,flexWrap:'wrap',justifyContent:'center'}}>
            <Typography sx={{color:'white'}}>
            {t('Portfolio')}
            </Typography>
        </Box>
        <Stack direction='row' sx={{display:'flex',alignItems:'center'
      ,flexWrap:'wrap',justifyContent:'center',mt:'30px'}} gap={1}>
            <Typography sx={{color:'#0854C1',fontWeight:'bold',fontSize:'30px'}}>
            {t('Previous')} 
            </Typography>
            <Typography sx={{color:'black',fontWeight:'bold',fontSize:'30px'}}>
        {t('Works')} 
            </Typography>
            </Stack>

<Container maxWidth='lg' sx={{mt:'30px'}}>
      <Swiper
      loop={true}
      modules={[Autoplay,Navigation]} 
      autoplay={{
       delay: 5000,
       disableOnInteraction: false,
        }}
        navigation={{
          prevEl: '.custom-swiper-prev',
          nextEl: '.custom-swiper-next',
        }}
        slidesPerView={matches?2:1}
        spaceBetween={0}
        style={{paddingBottom:'50px'}}
        className="custom-swiper"
      >
       {portfolio.map((p)=><>
       <SwiperSlide style={{alignItems:'center',display:'flex'
      ,flexWrap:'wrap',justifyContent:'center'}}>
        <Card sx={{ borderRadius:'20px 20px 20px 5px',width:matches?'400px':'250px',cursor:'pointer',height:'400px'}}
        onClick={()=>{navigate('/projectdetails',{ state: { data: p.id } })}}>
          <div style={{alignItems:'center',display:'flex'
      ,flexWrap:'wrap',justifyContent:'center'}}>
        <img src={p.logo} style={{width:'250px',height:'300px',objectFit:'cover'}}/>
        </div>
        <CardContent sx={{boxShadow: 'rgba(149, 157, 165, 0.2) 8px 8px 24px 8px',bgcolor:'white',height:'100px'}}>
          <Typography gutterBottom variant="h5">
         {p.title}
          </Typography>
          <Typography >
          {p.description.substring(0, 100)}
          </Typography>
        </CardContent>
    </Card>
        </SwiperSlide> </>)}

      </Swiper>
      </Container>
      {matches && <>
      <button className="custom-swiper-prev">
        <ArrowBackIcon className='ico'/>
      </button>
      <button className="custom-swiper-next">
        <ArrowForwardIcon className='ico'/>
      </button>
      </>}
      </Stack>
      </Box>
    </div>
  )
}
