import {
  useMediaQuery,
  Stack,
  Typography,
  Container,
  Button,
  Box,
} from "@mui/material";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "../App.css";

export default function Slider({ sliders }) {
  const matches = useMediaQuery("(min-width:740px)");
  return (
    <div style={{ marginTop: matches ? "120px" : "60px" }}>
      <Container maxWidth="lg">
        <Swiper
          loop={true}
          modules={[Autoplay, Pagination]}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          centeredSlides={true}
          pagination={{
            clickable: true,
            renderBullet: function (index, className) {
              return (
                '<span class="' + className + '">' + (index + 1) + "</span>"
              );
            },
          }}
          slidesPerView={1}
          spaceBetween={200}
          initialSlide={1}
          style={{ paddingBottom: "70px",width: "100%", }}
          className="custom-swiper"
        >
          {sliders.map((slide) => (
            <SwiperSlide
              style={{
                display: matches ? "" : "flex",
                alignItems: matches ? "" : "center",
                justifyContent: matches ? "" : "center",
              }}
            >
              <Stack
                direction={matches ? "row" : "column"}
                gap={matches ? "20px" : ""}
                sx={{ justifyContent: "start", alignItems: "start" }}
              >
                <Stack>
                  <Container maxWidth="sm">
                    <Typography
                      sx={{
                        color: "black",
                        fontWeight: "bolder",
                        fontSize: "30px",
                        mt: "100px",
                      }}
                    >
                      {slide.title}
                    </Typography>
                  </Container>

                  <Container maxWidth="sm">
                    <Typography sx={{ color: "black", mt: "20px" }}>
                      {slide.description}
                    </Typography>
                  </Container>

                  <Button
                    href={slide.button_action}
                    sx={{
                      color: "white",
                      bgcolor: "#0854C1",
                      "&:hover": { color: "white", bgcolor: "#0854C1" },
                      borderRadius: "30px 30px 30px 0px",
                      textTransform: "none",
                      width: matches ? "150px" : "200px",
                      mt: "30px",
                      ml: "20px",
                    }}
                  >
                    {slide.button_text}
                  </Button>
                </Stack>
                <Box sx={{ display: "flex", justifyContent: "end",flexWrap:'wrap',width:'60%' }}></Box>
                <img
                  src={slide.image}
                  alt="user"
                  style={{
                    width: "350px",
                    height: "350px",
                    objectFit: "contain",
                  }}
                  referrerPolicy="no-referrer"
                />
                <br />
              </Stack>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </div>
  );
}
