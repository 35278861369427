import { useMediaQuery, Container, Stack, Typography, Box, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import pic from '../Media/faq.png'
import FAQsComp from '../Components/FAQsComp';
import Rocket from '../Components/Rocket'
import DialogComp from '../Components/DialogComp';
import axios from 'axios';
import LoadingPage from '../Components/LoadingPage';
import { useTranslation } from 'react-i18next';

export default function FAQs() {
    const matches = useMediaQuery('(min-width:910px)');
    const [activePage, setActivePage] = useState('');
    const [open, setOpen] = useState(false);
    const [openLoad, setOpenLoad] = useState(true);
    const [faqs,setFaqs] = useState([])
    const [faq,setFaq] = useState([])
    const lang = localStorage.getItem('lang')
    const { t } = useTranslation();

    useEffect(()=>{
      window.scrollTo(0,0)
      axios.get(`${process.env.REACT_APP_API_URL}faqs`,{
        headers: {
          'Accept-Language': lang
        }
      }).then(res=>{
        setFaqs(res.data.data.faqs)
        setActivePage(res.data.data.faqs[0].title)
        setFaq(res.data.data.faqs[0].faqs)
        setOpenLoad(false)
      })
  },[])
  console.log(faqs)
  return (
    <div>
            <Box
        sx={{
          position: "absolute",
          top: "250px",
          left: "2%",
          transform: "translateX(-50%)",
          width: "15px",
          height: "15px",
          borderRadius: "50%",
          bgcolor: "#0854C1",
        }}
      />
     {  matches && <Box
        sx={{
          position: "absolute",
          top: "150px",
          left: "60%",
          transform: "translateX(-50%)",
          width: "30px",
          height: "30px",
          borderRadius: "50%",
          bgcolor: "#0854C1",
        }}
      />}
          <Box
        sx={{
          position: "absolute",
          top: "400px",
          right: "2%",
          transform: "translateX(-50%)",
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          bgcolor: "#0854C1",
        }}
      />
      {matches &&    <Box
        sx={{
          position: "absolute",
          top: "90%",
          right: "60%",
          transform: "translateX(-50%)",
          width: "15px",
          height: "15px",
          borderRadius: "50%",
          bgcolor: "#0854C1",
          zIndex:1
        }}
      />}
          <Box
        sx={{
          position: "absolute",
          top: "700px",
          right: "1%",
          transform: "translateX(-50%)",
          width: "20px",
          height: "20px",
          borderRadius: "50%",
          bgcolor: "#0854C1",
          zIndex:1
        }}
      />
      {openLoad && <LoadingPage open={openLoad} />}


       {!openLoad &&<> <Stack direction='row' sx={{display:'flex',flexWrap:'wrap',
    bgcolor:'#EDF4FF',width:'100%',alignItems:'center',justifyContent:'center'}} >
        <Stack>
        <Container maxWidth='xs' sx={{ml:matches?'50px':''}}>
        <Typography sx={{fontWeight:'bolder',fontSize:'30px',marginTop:matches?'0px':'100px',color:'#0854C1'}}>
        {t('FAQs')}
        </Typography>
        <Typography >
        {t('Have questions? Here you’ll find the answers most valued by our partners, along with access to step-by-step instructions and support.')}
        </Typography>
        </Container>
        </Stack>

        <img src={pic} style={{width:matches?'400px':'300px',height:matches?'400px':'300px',marginTop:matches?'100px':'100px'}}/>
        </Stack>

        <Container maxWidth='lg'>
        <Stack direction='row' sx={{display:'flex',flexWrap:'wrap',alignItems:'center'}}>
        <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'start',
                mt:'50px'
              }}
            >
              {faqs.map((f) => (
                <Button
                  sx={{
                    color: activePage === f.title ? '#0854C1' : 'black',
                    display: 'flex',
                    fontSize: '12px',
                    justifyContent:'center',
                    alignItems:'center',
                    fontWeight: activePage === f.title ? 'bold' : 'normal',
                  }}
                  onClick={()=>{setActivePage(f.title);setFaq(f.faqs)}}
                >
                  {f.title} 
                </Button>
              ))}
            </Box>
            <Stack>
        <Box sx={{mt:'60px'}}>
        <FAQsComp faqs={faq} />
        </Box>
          <Box sx={{width:'100%',display:'flex',flexWrap:'wrap',justifyContent:matches?'end':'center'}}>
        <Button sx={{ color: 'white',
                      bgcolor: '#0854C1',
                      '&:hover': { color: 'white', bgcolor: '#0854C1' },
                      borderRadius: '30px 30px 30px 0px',textTransform:'none',width:matches?'150px':'200px',mt:'100px'}}
                      onClick={()=>{setOpen(true)}}>
                    {t('Still Have a Question')}
                    </Button>
                    </Box>
                    </Stack>
        </Stack>
        </Container>

        <Rocket />
        <DialogComp open={open} setOpen={setOpen} />
        </>}
    </div>
  )
}
