import { Box, Button, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import LoadingPage from '../Components/LoadingPage';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function Blog() {
  const [open, setOpen] = useState(true);
  const [blogs,setBloga] = useState([])
  const lang = localStorage.getItem('lang')
  const { t } = useTranslation();

  const navigate = useNavigate()
    useEffect(()=>{
        window.scrollTo(0,0)
        axios.get(`${process.env.REACT_APP_API_URL}blog`,{
          headers: {
            'Accept-Language': lang
          }
        }).then(res=>{
          setBloga(res.data.data.blogs)
          setOpen(false)
        })
    },[])
  return (
    <div  style={{marginTop:'100px',display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',position:'relative'}}>
{open && <LoadingPage open={open}/>}
<Box
                        sx={{
                          position: 'absolute',
                          top: '60px',
                          left: '5%',
                          transform: 'translateX(-50%)',
                          width: '15px',
                          height: '15px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />


<Box
                        sx={{
                          position: 'absolute',
                          top: '250px',
                          left: '2%',
                          transform: 'translateX(-50%)',
                          width: '20px',
                          height: '20px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />


<Box
                        sx={{
                          position: 'absolute',
                          top: '640px',
                          right: '5%',
                          transform: 'translateX(-50%)',
                          width: '20px',
                          height: '20px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />

<Box
                        sx={{
                          position: 'absolute',
                          top: '100%',
                          left: '5%',
                          transform: 'translateX(-50%)',
                          width: '15px',
                          height: '15px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />


<Box
                        sx={{
                          position: 'absolute',
                          top: '110%',
                          right: '7%',
                          transform: 'translateX(-50%)',
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />


    {!open && <>
    <Typography 
    sx={{display:'flex',flexWrap:'wrap',alignItems:'center',justifyContent:'center',
    fontSize:'20px',fontWeight:'bold',color:'#0854C1',width:'100%',mt:'40px'}}>{t('Our BLog')}</Typography>
       <Stack  direction='row' sx={{display:'flex',
         flexWrap:'wrap',alignItems:'center',justifyContent:'center',mt:'40px'}} gap={4}>
       { blogs.map((b)=>
       <Card sx={{ borderRadius:'15px 15px 15px 5px',width:'300px',boxShadow: 'rgba(149, 157, 165, 0.2) 8px 8px 24px 8px'}}>
    <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',padding:'20px'}}>
    <img src={b.image} style={{width:'270px',height:'200px',objectFit:'cover',borderRadius:'20px'}}/>
    </div>
    <CardContent>
      <Typography gutterBottom variant="h5" component="div">
      {b.title}
      </Typography>

      <Typography gutterBottom>
      {b.description.substring(0, 50)}
      </Typography>
       <Stack direction='row' sx={{alignItems:'start',justifyContent:'start',mt:'30px',position:'relative'}} gap={7}>
      <Typography sx={{color:'#0B0D39'}}>
      {b.duration}· {b.date}
      </Typography>
      <button className={`b ${localStorage.getItem('lang') === 'en' ? 'en' : 'ar'}`} 
      onClick={()=>{navigate('/blogdetail',{state:{data:b.id }})}}>
        {localStorage.getItem('lang') === 'ar' ? 
        <ArrowBackIcon />
        :
        <ArrowForwardIcon/>}
      </button>
      </Stack>
    </CardContent>
</Card>)}
</Stack> </>}

    </div>
  )
}
