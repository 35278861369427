import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Container } from '@mui/material';


export default function AboutCards({title,content}) {

  return (
    <div style={{display:'flex'
    ,flexWrap:'wrap',justifyContent:'center',alignItems:'center',position:'relative'}}>  
    <Box sx={{width:'50%',bgcolor:'white',boxShadow:'rgba(149, 157, 165, 0.2) 8px 8px 24px 8px',
    display:'flex',position:'absolute',top:'-20px'
,flexWrap:'wrap',justifyContent:'center',alignItems:'center',border:' 0.1px solid rgba(128, 128, 128, 0.361)'
,borderRadius:'30px',p:'10px'}}>
    <Typography sx={{color:'black',fontWeight:'bolder'}} variant='h5'>
       {title}
    </Typography>
    </Box>
    <Card sx={{ minWidth: 335,display:'flex'
    ,flexWrap:'wrap',justifyContent:'center',alignItems:'center'
    ,borderRadius:'20px',bgcolor:'#EDF4FF',minHeight:'250px',boxShadow:'none' }}>
      <CardContent sx={{p:'40px'}}>
        <Container maxWidth='xs'>
        <Typography sx={{textAlign:'center'}}>
          {content}
        </Typography>
        </Container>
      </CardContent>
    </Card>
    </div>
  );
}