import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import logo from './Media/logo.png';
import { useNavigate } from 'react-router-dom';
import { Link, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DialogComp from './Components/DialogComp';
import { useTranslation } from 'react-i18next';
import i18next from "i18next";
import LanguageIcon from '@mui/icons-material/Language';
import { motion } from "framer-motion";


function Navbar() {
  const { t, i18n} = useTranslation();
  const dir = i18n.dir();
  const [langMenu, setLangMenu] = React.useState(false);
  document.body.dir = i18n.dir();
  const pages = [t('Home'), t('about Us'), t('services'), t('portfolio'), t('blog'), t('FAQs')];
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [activePage, setActivePage] = useState(localStorage.getItem('p'));
  const navigate = useNavigate();
  const matches = useMediaQuery('(min-width:1100px)');
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const handleNavigation = (p) => {
    localStorage.setItem('p', p);
    let pageInEnglish;
    switch (p) {
      case t('Home'):
        pageInEnglish = 'Home';
        break;
      case t('about Us'):
        pageInEnglish = 'aboutUs';
        break;
      case t('services'):
        pageInEnglish = 'services';
        break;
      case t('portfolio'):
        pageInEnglish = 'portfolio';
        break;
      case t('blog'):
        pageInEnglish = 'blog';
        break;
      case t('FAQs'):
        pageInEnglish = 'FAQs';
        break;
      default:
        pageInEnglish = p;
        break;
    }
  
    if (pageInEnglish === 'Home') {
      navigate('/');
      window.scrollTo(0, 0);
      setLangMenu(false)
    } else if (pageInEnglish === 'services') {
      navigate('/innerService');
      setLangMenu(false)
    } else {
      navigate(`/${pageInEnglish}`);
      setLangMenu(false)
    }
  
    setActivePage(localStorage.getItem('p'));
  };


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    setLangMenu(false)
  };

  const handleEn = () => {
    i18next.changeLanguage('en');
    handleCloseLangMenu();
    localStorage.setItem('lang','en')
    window.location.reload(true)
  };

  const handleAr = () => {
    i18next.changeLanguage('ar');
    handleCloseLangMenu();
    localStorage.setItem('lang','ar')
    window.location.reload(true)
  };
  const handleCloseLangMenu = () => {
    setLangMenu(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setLangMenu(false);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handelingLang =() =>{
    setLangMenu(!langMenu)
    setAnchorElNav(false)
  }

  return (
    <AppBar position="fixed" sx={{ bgcolor: 'transparent', boxShadow: 'none', 
    justifyContent: 'center', alignItems: 'center'}}>
      <Box sx={{ bgcolor: 'white', borderRadius: '50px', mt: '20px', 
      width: '92%', boxShadow: 'rgba(149, 157, 165, 0.2) 8px 8px 24px 8px' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                padding: '10px',
              }}
            >
              <img src={logo} style={{ width: '100px', height: '50px', objectFit: 'contain' }} />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="black"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                onClose={() => {
                  setAnchorElNav(null);
                }}
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                sx={{
                  display: { xs: 'block', md: 'none' },
                  '.MuiPaper-root': {
                    width: '80%',
                    bgcolor: 'white',
                    mt: '5px',
                  },
                }}
              >
                <MenuItem>
                  <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                      mr: 2,
                      display: { xs: 'flex', md: 'none' },
                    }}
                  >
                    <img src={logo} style={{ width: '100px', height: '50px', objectFit: 'contain' }} />
                  </Typography>
                </MenuItem>
                {pages.map((page) => (
               <Button
               key={page}
               sx={{
                 color: '#0B0D39',
                 display: 'flex',
                 fontSize: '12px',
                 justifyContent:'center',
                 alignItems:'center',
                 fontWeight: activePage === page ? 'bold' : 'normal',
               }}
               onClick={() => {
                setAnchorElNav(false)
                handleNavigation(page)
                //  if (page === 'solutions') {
                //    setIsDropdownOpen(true);
                //  } else {
                //    setIsDropdownOpen(false);
                //  }
               }}
             >
               {page} {page === 'solutions' && <KeyboardArrowDownIcon />}
               {activePage === page && (
                   <Box
                     sx={{
                       position: 'absolute',
                       top: '30px',
                       left: '50%',
                       transform: 'translateX(-50%)',
                       width: '8px',
                       height: '8px',
                       borderRadius: '50%',
                       bgcolor: '#356DFF',
                     }}
                   />
                 )}
             </Button>
              ))}
        <ListItemIcon>
        <a onClick={handelingLang}>
          <LanguageIcon sx={{ color: '#0854C1',cursor:'pointer',ml:'20px',mt:'10px' }} onClick={handelingLang}/>
          </a>        
        </ListItemIcon>
              </Menu>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                gap: matches ? '40px' : '',
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page}
                  sx={{
                    color: '#0B0D39',
                    display: 'flex',
                    fontSize: '12px',
                    justifyContent:'center',
                    alignItems:'center',
                    fontWeight: activePage === page ? 'bold' : 'normal',
                  }}
                  onClick={() => {
                    handleNavigation(page)
                    // if (page === 'solutions') {
                    //   setIsDropdownOpen(true);
                    // } else {
                    //   setIsDropdownOpen(false);
                    // }
                  }}
                >
                  {page} 
                  {activePage === page && (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '30px',
                          left: '50%',
                          transform: 'translateX(-50%)',
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          bgcolor: '#356DFF',
                        }}
                      />
                    )}
                </Button>
              ))}
                <MenuItem sx={{position:'relative'}}>
        <ListItemIcon>
        <a onClick={() => setLangMenu(!langMenu)}>
          <LanguageIcon sx={{ color: '#0854C1',cursor:'pointer' }} onClick={() => setLangMenu(!langMenu)}/>
          </a>        
        </ListItemIcon>
      </MenuItem>
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip>
                <Link style={{ textDecoration: 'none' }} target="_blank">
                  <Button
                    sx={{
                      color: 'white',
                      bgcolor: '#0854C1',
                      '&:hover': { color: 'white', bgcolor: '#0854C1' },
                      padding: '5px 20px 5px 20px',
                      borderRadius: '30px',
                    }}
                    onClick={()=>{setOpen(true)}}
                  >
                    {t("Let's Discuss")}
                  </Button>
                </Link>
              </Tooltip>
            </Box>
          </Toolbar>
        </Container>
      </Box>
      
      {/* {isDropdownOpen && activePage === 'solutions' && <>
            {services.map((page) => (
                  <MenuItem 
                  key={page} sx={{bgcolor:'white',width:'15%',
                   boxShadow: 'rgba(149, 157, 165, 0.2) 1px 1px 1px 1px',mr:'100px',zIndex:1 }}
                   onClick={()=>{navigate('/innerservice');setIsDropdownOpen(false)}}>
                    <Typography textAlign="center" 
                    sx={{ color: '#333134'}}>
                      {page.title}
                    </Typography>
                  </MenuItem>
                  
                ))}
                </>} */}

                <DialogComp open={open} setOpen={setOpen} />
          {langMenu && (
                  <motion.div         
                  initial={{ opacity: 0, x: 500 }}
                  whileInView={{ opacity: 1 }}
                  animate={{ x: 0 }}
                  transition={{ delay: 0.3, type: "spring", stiffness: 50 }}
                  viewport={{ once: true }}>
                    <Stack direction='row' sx={{display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center'}} gap={1}>
                    <button className='navBtn' onClick={handleAr}>
                       {t('Arabic')}
                    </button>
                    <button className='navBtn' onClick={handleEn}>
                      {t('English')}
                    </button>
                    </Stack>
                  </motion.div>
                )}
    </AppBar>
  );
}

export default Navbar;