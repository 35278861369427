import React, { useEffect, useState } from 'react'
import { Box, Button, Container, Stack, Typography, useMediaQuery } from '@mui/material'
import Rocket from '../Components/Rocket';
import { useNavigate } from 'react-router-dom';
import LoadingPage from '../Components/LoadingPage';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export default function InnerService() {
  const [activePage, setActivePage] = useState('');
  const [open, setOpen] = useState(true);
  const [service,setService] = useState([])
  const matches = useMediaQuery('(min-width:600px)');
  const navigate = useNavigate()
  const lang = localStorage.getItem('lang')
  const { t } = useTranslation();

  useEffect(()=>{
    window.scrollTo(0,0)
    axios.get(`${process.env.REACT_APP_API_URL}services`,{
      headers: {
        'Accept-Language': lang
      }
    }).then(res=>{
      setOpen(false)
      setService(res.data.data.services)
      setActivePage(res.data.data.services[0].title)
    })
},[])
  return (
    <div style={{marginTop:'150px'}}>
      <Box
                        sx={{
                          position: 'absolute',
                          top: '150px',
                          right: '7%',
                          transform: 'translateX(-50%)',
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '200px',
                          left: '10%',
                          transform: 'translateX(-50%)',
                          width: '15px',
                          height: '15px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '500px',
                          right: '1%',
                          transform: 'translateX(-50%)',
                          width: '15px',
                          height: '15px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />
                         <Box
                        sx={{
                          position: 'absolute',
                          top: '750px',
                          left: '5%',
                          transform: 'translateX(-50%)',
                          width: '15px',
                          height: '15px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                          zIndex:1
                        }}
                      />
                      {open && <LoadingPage open = {open} />}
      <Typography 
      sx={{display:'flex',flexWrap:'wrap',alignItems:'center',justifyContent:'center',
      fontSize:'20px',fontWeight:'bold',color:'#0854C1'}}>{t('Our Services')}
      </Typography>
{!open && <>
{/* <Stack direction='row' sx={{display:'flex',flexWrap:'wrap',alignItems:'center',justifyContent:'center',mt:'0px'
}} gap={matches?5:2}>
    {service.map((s)=>
<Button
            sx={{
                color: activePage === s.title ?'white':'#0854C1',
                bgcolor: activePage === s.title ? '#0854C1' : 'transparent',
                '&:hover': { color: 'white', bgcolor: '#0854C1' },
                borderRadius: '30px 30px 30px 0px',textTransform:'none',width:'150px',mt:'50px',
            border:activePage===s.title?'':'1px solid #0854C1'}}
          onClick={()=>{setActivePage(s.title)}}>
            {s.title}
          </Button>
          )}
</Stack> */}
<Container maxWidth='lg' sx={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center'
,gap:'50px',mt:'60px'}}>
{service.map((s)=>
<article>
<div class="article-wrapper">
<img src={s.image} alt="" style={{paddingTop:'5px'}}/>
<div class="article-body">
<h2>{s.title}</h2>
<p>
{s.description.substring(0, 100)}
        </p>
<a onClick={()=>{navigate('/servicedetails',{state:{data:s.id}})}} class="read-more">
  {t('Read more')} 
  <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 20 20" fill="currentColor">
    <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
  </svg>
</a>
</div>
</div>
</article>
)}
</Container>

<Rocket />
</>}
    </div>
  )
}
