import React from 'react'
import { useMediaQuery } from '@mui/material'


export default function Video({vid}) {
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <div>
        <div style={{position:'relative',display:'flex',flexWrap:'wrap',justifyContent:'center'
        ,alignItems:'center',marginTop:'100px'}}> 
       <video src={vid} style={{width:matches?'70%':'350px',borderRadius:matches?'50px':''
       ,marginBottom:matches?'':'70px',height:matches?'':'350px'}}
       autoPlay loop muted></video>
    </div>
    </div>
  )
}
