import { Box, Button, Container, Stack, Typography,useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import DialogComp from './DialogComp';
import { useTranslation } from 'react-i18next';

export default function AboutUs({aboutUs}) {
    const matches = useMediaQuery('(min-width:500px)');
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

  return (
    <div style={{marginTop:'60px',position:'relative'}}>
           <Box
                        sx={{
                          position: 'absolute',
                          top: '-50px',
                          left: '40px',
                          transform: 'translateX(-50%)',
                          width: '15px',
                          height: '15px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                        }}
                      />
      <Container  maxWidth='lg'>
        <Stack direction='row' sx={{display:'flex',alignItems:'center'
      ,flexWrap:'wrap',justifyContent:matches?'start':'center'}} gap={'15%'}>
        <Stack>
      <Box sx={{mt:'30px',borderRadius:'30px',bgcolor:'#0854C1',p:'10px',height:'20px',width:'90px',
      display:'flex'
      ,flexWrap:'wrap',justifyContent:'center',ml:'20px'}}>
            <Typography sx={{color:'white'}}>
            {t('About us')}
            </Typography>
        </Box>
        <Container maxWidth='sm'>
        <Stack direction='row' sx={{display:'flex',alignItems:'start'
      ,flexWrap:'wrap',justifyContent:'start',mt:'30px',}} gap={1}>
        <Typography sx={{color:'black',fontWeight:'bold',fontSize:'30px'}}>
        {aboutUs.title}
            </Typography>
            {/* <Typography sx={{color:'#0854C1',fontWeight:'bold',fontSize:'30px'}}>
            {t('IDEA')} 
            </Typography><Typography sx={{color:'black',fontWeight:'bold',fontSize:'30px'}}>
        {t('to')}
            </Typography> <Typography sx={{color:'#0854C1',fontWeight:'bold',fontSize:'30px'}}>
            {t('DESIGN')}  
            </Typography> <br/>
            <Typography sx={{color:'black',fontWeight:'bold',fontSize:'30px'}}>
            {t('to be')} 
            </Typography><Typography sx={{color:'#0854C1',fontWeight:'bold',fontSize:'30px'}}>
           {t('PRODUCT.')}  
            </Typography> */}
            </Stack>
            <Typography sx={{mt:'20px'}}  
            dangerouslySetInnerHTML={{ __html: aboutUs.about_us.content }}>
            </Typography>
            
            <Button sx={{ color: 'white',
                      bgcolor: '#0854C1',
                      '&:hover': { color: 'white', bgcolor: '#0854C1' },
                      borderRadius: '30px 30px 30px 0px',
                      textTransform:'none',width:matches?'150px':'200px',mt:'30px'}} onClick={()=>{setOpen(true)}}>
                    {t('Get to know us better')}
                    </Button>
            </Container>
        </Stack>


        <img src={aboutUs.about_us.about_image} style={{height:'350px',width:'300px',
        objectFit:'cover',marginTop:matches?'100px':'20px',marginTop:'50px'}}/>
       {matches && <Box
                        sx={{
                          position: 'absolute',
                          top: '400px',
                          left: '70%',
                          transform: 'translateX(-50%)',
                          width: '30px',
                          height: '30px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                        }}
                      />}

<Box
                        sx={{
                          position: 'absolute',
                          top: '250px',
                          left: '95%',
                          transform: 'translateX(-50%)',
                          width: '15px',
                          height: '15px',
                          borderRadius: '50%',
                          bgcolor: '#0854C1',
                        }}
                      />
        </Stack>
      </Container>
      <DialogComp open={open} setOpen={setOpen} />

    </div>
  )
}
